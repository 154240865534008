// Migrated
<template lang="pug">
#side-menu.offcanvas.offcanvas-end(
  v-click-outside="{ fn: closeMenu, ignore: '.mobile-btn' }"
  tabindex="-1",
  aria-labelledby="offcanvasExampleLabel"
  :class="{ 'show': sideMenuActive }"
)
  .offcanvas-body
    .side-menu.pb-3.overflow-auto(
      :class="{ 'side-menu-collapsed': collapsed, 'side-menu-collapsible': collapsible, 'side-menu-overflow': overflow }"
    )
      NuxtLazyHydrate(:when-triggered="!collapsed")
        LazyAlgoliaSearchBar.mb-1(
          :header="true"
          :autofocus="false"
          @search="closeSideMenuActive()"
          @hide-search-bar="closeSideMenuActive()"
        )

      .sidemenu-collapse(
        v-if="collapsible"
        @click="collapsed = !collapsed"
      )
        button.btn.btn-link.btn-white
          fa(
            :icon="collapsed ? 'chevron-down' : 'chevron-up'"
            width="11"
          )
      .submenu-overlay(
        v-show="activeSubmenu"
        @click="setSubmenu(null)"
      )

      ul.side-menu-section(
        v-for="(navItem, index) in topNavbar"
        :key="index"
      )
        li.side-menu-title(
          :class="activeHamburgerMenu === navItem.label ? 'active' : 'non'"
          @click="toggleMenu(navItem.label)"
        )
          span.d-flex.justify-content-between(v-if="navItem.submenu && navItem.submenu.length") {{ navItem.label }}
            fa(
              :icon="activeHamburgerMenu === navItem.label ? 'chevron-up' : 'chevron-down'"
              width="11"
            )
          nuxt-link(
            v-else
            :to="navItem.link"
            @click="closeMenu"
          ) {{ navItem.label }}
          ul.side-menu-dropdown-list.blue-containter.side-menu-dropdown-list-mobile(
            v-if="navItem.submenu"
            :ref="`submenu_${navItem.label}`"
            :class="{ show: activeHamburgerMenu === navItem.label }"
            :style="{ height: getHeight(navItem.label) }"
          )
            li(
              v-for="(item, idx) in navItem.submenu"
              :key="idx"
            )
              .dropdown-divider(v-if="item.label === 'linebreak'")
              a(
                v-else-if="item.absoluteLink",
                :href="item.link"
                target="_blank"
              ) {{ item.label }}
              nuxt-link(
                v-else
                :to="item.link"
                @click="closeMenu"
              ) {{ item.label }}
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { faCalendar } from '@fortawesome/pro-duotone-svg-icons'

export default defineNuxtComponent({
  name: 'Sidebar',

  props: {
    collapsible: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      activeSubmenu: null,
      activeHamburgerMenu: null,
      collapsed: this.collapsible,
      overflow: false,
      timer: null
    }
  },

  computed: {
    ...mapState(useRootStore, {
      topNavbar: 'topNavbar'
    }),

    ...mapState(useMenuStore, {
      sideMenuActive: 'sideMenuActive'
    })
  },

  watch: {
    collapsible (v) {
      if (v) {
        this.collapsed = true
      } else {
        this.collapsed = false
      }
    },

    collapsed (v) {
      if (v) {
        this.overflow = false
        this.setSubmenu(null)
      } else {
        this.timer = window.setTimeout(() => { this.overflow = true }, 1000)
      }
    }
  },

  mounted () {
    window.addEventListener('resize', this.resizeListener)
  },

  beforeUnmount () {
    clearTimeout(this.timer)
    window.removeEventListener('resize', this.resizeListener)
  },

  methods: {
    ...mapActions(useMenuStore, ['closeSideMenuActive']),

    resizeListener () {
      if (window.innerWidth > 768) {
        this.closeSideMenuActive()
      }
    },

    icon (icon) {
      if (icon === 'calendar') {
        return faCalendar
      }

      return icon
    },

    setSubmenu (submenu) {
      this.activeSubmenu = submenu
    },

    closeMenu () {
      this.closeSideMenuActive()
      // since iphone safari doesn't activate the watcher while switching routes, this has to be done manually. thanks apple.
      window.document.body.classList.remove('sideMenuActive')
      window.document.documentElement.classList.remove('sideMenuActive')
    },

    toggleMenu (menu) {
      if (this.activeHamburgerMenu === menu) {
        this.activeHamburgerMenu = null
      } else {
        this.activeHamburgerMenu = menu
      }
    },

    getHeight (label) {
      if (this.activeHamburgerMenu === label) {
        // +32 for padding offset
        return `${this.$refs[`submenu_${label}`][0]?.scrollHeight + 32}px`
      }
    }
  }
})
</script>
